<template>
  <section>
    <h1>Formulario de avaliação</h1>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
